export const config = {
  navigation: [
    {
      title: 'Home',
      href: '/'
    },
    {
      title: 'FAQ',
      href: '/faq'
    },
    {
      title: 'Contact',
      href: '/contact'
    },
  ]
};