import React, { useState } from 'react';
import { Link } from 'gatsby';
import { config } from '../site.config';

import Transition from './Transition';
import logo from '../img/logo.png';

const Navbar = (props) => {
  const [ isMobileNavOpen, setIsMobileNavOpen ] = useState(false);
  const ctaButtonText = 'Call or text us!'
  
  return (
    <header className="relative bg-white z-20 border-b-2 border-solid border-gray-100">
      <div className="max-w-5xl mx-auto flex justify-between items-center px-4 py-6 sm:px-6 md:justify-start md:space-x-10">
        <div>
          <Link to="/" className="flex">
            <img className="w-auto h-10 sm:h-12" src={logo} alt="Open Land Investments Logo" />
          </Link>
        </div>
        <div className="-mr-2 -my-2 md:hidden">
          <button onClick={() => setIsMobileNavOpen(true)} type="button" className="inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:bg-gray-100 focus:text-gray-500 transition duration-150 ease-in-out">
            <svg className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 6h16M4 12h16M4 18h16"/>
            </svg>
          </button>
        </div>

        <div className="hidden md:flex-1 md:flex md:items-center md:justify-between md:space-x-12">
          <nav className="flex space-x-10"> 
            {config.navigation.map(({ title, href }, index) => {
              return (
                <Link key={index} to={href} className="text-base leading-6 font-medium text-gray-500 hover:text-gray-900 focus:outline-none focus:text-gray-900 transition ease-in-out duration-150">
                  {title}
                </Link>
              );
            })}
          </nav>

          <div className="flex items-center space-x-8">
            <a href={`tel:775-312-6171`} className="w-full flex items-center justify-center px-4 py-2 border border-transparent text-base leading-6 font-medium rounded-md text-white bg-green-400 hover:bg-green-500 focus:outline-none focus:border-green-500 focus:shadow-outline-green active:bg-green-500 transition ease-in-out duration-150">
              <svg className="h-5 w-5 mr-2 text-white" fill="none" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" viewBox="0 0 24 24" stroke="currentColor"><path d="M3 5a2 2 0 012-2h3.28a1 1 0 01.948.684l1.498 4.493a1 1 0 01-.502 1.21l-2.257 1.13a11.042 11.042 0 005.516 5.516l1.13-2.257a1 1 0 011.21-.502l4.493 1.498a1 1 0 01.684.949V19a2 2 0 01-2 2h-1C9.716 21 3 14.284 3 6V5z"></path></svg>
              {ctaButtonText}
            </a>
          </div>
        </div>
      </div>

      <Transition
        show={isMobileNavOpen}
        enter="duration-200 ease-out"
        enterFrom="opacity-0 scale-95"
        enterTo="opacity-100 scale-100"
        leave="duration-100 ease-in"
        leaveFrom="opacity-100 scale-100"
        leaveTo="opacity-0 scale-95"
      >
        <div className="absolute top-0 inset-x-0 p-2 transition transform origin-top-right md:hidden">
          <div className="rounded-lg shadow-lg">
            <div className="rounded-lg shadow-xs bg-white divide-y-2 divide-gray-50">
              <div className="pt-5 pb-6 px-5 space-y-6">
                <div className="flex items-center justify-between">
                  <Link to="/">
                    <img className="h-10 w-auto" src={logo} alt="Open Land Investments Logo" />
                  </Link>
                  <div className="-mr-2">
                    <button onClick={() => setIsMobileNavOpen(false)} type="button" className="inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:bg-gray-100 focus:text-gray-500 transition duration-150 ease-in-out">
                      <svg className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12"/>
                      </svg>
                    </button>
                  </div>
                </div>
                <div>
                  <nav className="grid gap-6">
                    {config.navigation.map(({ title, href }, index) => {
                      return (
                        <Link key={index} to={href} className="-m-3 p-3 flex items-center space-x-4 rounded-lg hover:bg-gray-50 transition ease-in-out duration-150">                         
                          <div className="text-base leading-6 font-medium text-gray-900">
                            {title}
                          </div>
                        </Link>
                      );
                    })}                                                                           
                  </nav>
                </div>
              </div>
              <div className="py-6 px-5 space-y-6">                
                <div className="space-y-6">
                  <span className="w-full flex rounded-md shadow-sm">
                    <a href={`tel:775-312-6171`} className="w-full flex items-center justify-center px-4 py-2 border border-transparent text-base leading-6 font-medium rounded-md text-white bg-green-400 hover:bg-green-500 focus:outline-none focus:border-green-500 focus:shadow-outline-green active:bg-green-500 transition ease-in-out duration-150">
                      {ctaButtonText}
                    </a>
                  </span>        
                </div>
              </div>
            </div>
          </div>
        </div>
      </Transition>
    </header>
  )
};

export default Navbar;
